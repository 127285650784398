<template>
  <div class="gateway news-center">
    <!-- header 头部 -->
    <gateway-header :bgAttr="'rgba(54, 89, 241, 1)'" />

    <div class="news-center-banner">
      <img src="../../assets/img/gateway/bg-banner-home.png" alt="" />
    </div>

    <div class="main-content gateway-module">
      <div class="module-content">
        <div class="sub-nav-box">
          <li
            :class="['ls-item', { active: ele === lsHoverIdx }]"
            v-for="ele in 3"
            :key="ele"
            @click="lsHoverIdx = ele"
          >
            新闻动态
          </li>
        </div>
        <div class="ls-box">
          <template v-if="lsHoverIdx === 3">
            <li :class="['ls-item']" v-for="item in 6" :key="item">
              <p>湛江海洋生态保护不停歇</p>
              <i>2021-09-18</i>
              <span>
                查看详情
                <img
                  src="../../assets/img/gateway/icon-arrow-gray.png"
                  alt=""
                />
                <img
                  class="img-active"
                  src="../../assets/img/gateway/icon-arrow-active.png"
                  alt=""
                />
              </span>
            </li>
          </template>
          <div class="column-new-layout" v-else>
            <ColumnNewLayout :dataList="newsls" />

            <div class="more-txt">查看更多<i></i></div>
          </div>
        </div>
      </div>
    </div>

    <gateway-footer />
    <ToTopperComponent />
  </div>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css'

import GatewayHeader from '../../components/gateway-header.vue'
import GatewayFooter from '../../components/gateway-footer.vue'
import ColumnNewLayout from '../../components/column-new-layout.vue'
import ToTopperComponent from '../../components/to-topper-component.vue'

export default {
  name: 'news-center',
  data() {
    return {
      newsls: [1, 2, 3, 1, 2],
      lsHoverIdx: 1,
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 2000, //1秒切换一次
        },
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  computed: {},
  methods: {
    clickItem(idx) {
      console.log(idx)
      this.lsHoverIdx = idx
    },
  },
  components: {
    GatewayHeader,
    GatewayFooter,
    swiper,
    swiperSlide,
    ColumnNewLayout,
    ToTopperComponent,
  },
  beforeMount() {
    // 模板编译/挂载之前
  },
  mounted() {
    // 模板编译/挂载之后
  },
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>
<style lang="less" scoped>
@import './news-center.less';

</style>
