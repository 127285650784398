<template>
  <div class="ls-body">
    <div
      class="item-wrap"
      v-for="ele in 8"
      :key="ele"
      :style="dataList.length > 3 ? '' : 'margin-bottom: 0px'"
    >
      <li class="ls-item">
        <div class="img">
          <img src="../assets/img/gateway/bg-banner-home.png" alt="" />
        </div>
        <span>湛江海洋生态保护不停歇</span>
        <p>2021-09-18</p>
        <i>
          查看详情
          <img src="../assets/img/gateway/icon-arrow-gray.png" alt="" />
          <img
            class="img-active"
            src="../assets/img/gateway/icon-arrow-active.png"
            alt=""
          />
        </i>
      </li>
    </div>
  </div>
</template>
<script>
export default {
  name: 'img-detail-layout',
  props: {
    dataList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
  components: {},
  beforeMount() {
    // 模板编译/挂载之前
  },
  mounted() {
    // 模板编译/挂载之后
  },
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>
<style lang="less" scoped>
.ls-body {
  display: flex;
  width: 100%;

  flex-wrap: wrap;
  .item-wrap {
    display: flex;
    width: 33.333%;
    margin-bottom: 56px;

    justify-content: center;
    &:nth-child(3n) {
      justify-content: flex-end;
    }
    &:nth-child(3n) + .item-wrap,
    &:first-child {
      justify-content: flex-start;
    }
    .ls-item {
      display: flex;
      flex-direction: column;
      width: 388px;
      cursor: pointer;

      align-items: flex-start;
      &:hover {
        .img img {
          transform: scale(1.1);
        }
        i {
          color: #3659f1;
          img {
            display: none;
          }
          .img-active {
            display: inline-block;
          }
        }
      }
      .img {
        display: flex;
        overflow: hidden;
        width: 388px;
        height: 218px;

        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          transition: transform 0.3s;
        }
      }
      span {
        margin-top: 10px;
        margin-bottom: 6px;
        color: #1b1e2a;
        font-family: 'Microsoft YaHei';
        font-size: 22px;
        font-weight: 400;
      }
      p {
        color: #666870;
        font-family: 'Microsoft YaHei';
        font-size: 16px;
        font-weight: 400;
      }
      i {
        margin-top: 16px;
        color: #1b1e2a;
        font-family: 'Microsoft YaHei';
        font-size: 18px;
        font-weight: 400;
        img {
          display: inline-block;
          width: 27px;
          height: 10px;
          margin-left: 12px;
          vertical-align: middle;
        }
        .img-active {
          display: none;
        }
      }
    }
  }
}

</style>
