<template>
  <div class="gateway sub-pages">
    <!-- header 头部 -->
    <gateway-header :bgAttr="'rgba(54, 89, 241, 1)'" />
    <div class="sub-pages-banner">
      <img src="../../assets/img/gateway/bg-banner-home.png" alt="" />
    </div>

    <div class="main-content gateway-module">
      <div class="module-title" v-show="pageTitle">{{ pageTitle }}</div>
      <div class="you-position" v-if="![].includes($route.query.type)">
        <i><img src="../../assets/icon/el-date-editor-icon.png" alt="" /></i>
        <p>
          您的位置:产业园服务>公共服务中心{{
            `${firstNavName ? '>' + firstNavName : ''}`
          }}>{{ lastNavName }}
        </p>
        <span @click="gobackpage">
          返回上级
          <img src="../../assets/img/gateway/icon-arrow-gray.png" alt="" />
          <img
            class="img-active"
            src="../../assets/img/gateway/icon-arrow-active.png"
            alt=""
          />
        </span>
      </div>
      <div class="module-content">
        <!-- 百度地图区域 -->
        <div class="map-content" v-if="authString.includes('map')">
          <div id="map-canvas"></div>
          <div class="window-wrap">
            <div class="wrap-top">
              <img
                class="logo"
                src="../../assets/icon/el-icon-search.png"
                alt=""
              />
              <span>广东恒兴集团有限公司</span>
            </div>
            <div class="text-wrap">
              <p>经度: 120.448156</p>
              <p>纬度: 33.665992</p>
            </div>
          </div>
        </div>

        <!-- 搜索框区域 -->
        <el-form
          :inline="true"
          :model="formInline"
          class="content-form-inline"
          v-if="authString.includes('search')"
        >
          <el-form-item label="年份">
            <el-select v-model="formInline.region" placeholder="年份">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
              <el-option label="234234" value="234234"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="品种来源">
            <el-input
              v-model="formInline.user"
              placeholder="品种来源"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getListData(true)"
              >查询</el-button
            >
          </el-form-item>
        </el-form>

        <!-- 表格模板 -->
        <div class="table-content-wrap" v-if="authString.includes('table')">
          <el-table :data="tablels">
            <el-table-column
              show-overflow-tooltip
              prop="name"
              label="分销员名称"
            ></el-table-column>
            <el-table-column
              width="200"
              prop="mobile"
              label="分销员手机号"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="today"
              label="今日成交"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="yesterday"
              label="昨日成交"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="sevenday"
              label="近7天成交"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="thirtyday"
              label="近30天成交"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="commission"
              label="累计佣金"
            ></el-table-column>
          </el-table>
        </div>

        <!-- 图片布局样式 -->
        <div class="rows-img-content" v-if="authString.includes('rowsImg')">
          <ImgDetailLayout :dataList="listData" />
        </div>

        <!-- 渔业在线学习 -->
        <div class="online-learn" v-if="authString.includes('onlineLearn')">
          <ColumnNewLayout :dataList="listData" />

          <div
            class="more-txt"
            @click="loadMore"
            v-if="listData.length > params.pageSize * params.pageNum"
          >
            查看更多<i></i>
          </div>
        </div>

        <!-- 页码 -->
        <el-pagination
          v-if="authString.includes('pagination')"
          :current-page="params.pageNum"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="params.pageSize"
          :total="params.total"
          layout="total, sizes, prev, pager, next"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          prev-text="上一页"
          next-text="下一页"
        >
        </el-pagination>

        <!-- 学术会议 列表 -->
        <div class="meeting-ls" v-if="$route.query.type == 2 && !isSecondLevel">
          <div class="ls-wrap">
            <li
              :class="['ls-item', { active: ele === 1 }]"
              v-for="ele in 6"
              :key="ele"
              @click="toDetail(ele)"
            >
              <th>《家鱼的生物学和人工系列》</th>
              <span>「作者」:施鼎钧</span>
              <span>「作者」:施鼎钧</span>
              <span>「作者」:施鼎钧</span>
              <i>查看详情</i>
            </li>
          </div>
          <div
            class="more-txt"
            @click="loadMore"
            v-if="listData.length > params.pageSize * params.pageNum"
          >
            查看更多<i></i>
          </div>
        </div>

        <!-- 学术会议 详情 -->
        <div
          class="meeting-detail"
          v-if="$route.query.type == 2 && isSecondLevel"
        >
          <p><span>「开幕日期」</span><i>2018-10-29 00:00</i></p>
          <p><span>「闭幕日期」</span><i>2019-09-02 17:00</i></p>
          <p><span>「会议城市」</span><i>昆明</i></p>
          <p><span>「会场地点」</span><i>详细地址会前通知</i></p>
          <p>
            <span>「主办单位」</span><i>中科地信（北京）遥感信息技术研究院</i>
          </p>
          <p><span>「联系人」</span><i>程老师</i></p>
          <p><span>「联系电话」</span><i>010-68692561</i></p>
          <p><span>「邮箱」</span><i>xinx@cafs.ac.cn</i></p>
          <p>
            <span>「会议简介」</span>
            <i
              >当前，国土空间综合整治已上升为国家层面的战略部署，全面推进国土综合整治，加快修复国土功能，提高国土开发利用质量和效益，已成
              为统筹推进现代化建设、生态文明建设、乡村振兴和城乡融合的关键要点。
              国务院新一轮机构改革提出编制实施国土空间生态修复规划这一创新举措，要求加大力度推进山水林田湖草生命共同体的全方位系统综合治理。科学编制国土空间生态修复规划，是系统实施国土空间生态修复重大工程的优先任务。
              为帮助各企业事业单位更好地推进生态文明新时代下的国土空间综合整治工作，把握从监测、治理到监管的全系列生态修复流程，中科地信（北京）遥感信息技术研究院特召开“生态文明新时代国土空间综合整治与生态修复技术”会议。</i
            >
          </p>
        </div>

        <!-- 人物简介 -->
        <div class="personage-introduction" v-if="$route.query.type == 3">
          <div class="person-info">
            <div class="img">
              <img src="../../assets/img/gateway/bg-banner-home.png" alt="" />
            </div>
            <div class="detail">
              <span>包振民</span>
              <i>「职位」 博士生导师</i>
              <p>
                「简介」
                包振民，男，1961年12月出生，博士，中国工程院院士，中国海洋大学海洋生命学院院长、博士生导师，国家自然科学基金委生命科学部专家咨询委员会委员、国务院学位委员会生物学科评议组成员、中国动物学会贝类分会副理事长、山东省科学技术协会第九届委员会副主席、山东省青年科学家协会主席。
              </p>
            </div>
          </div>
          <div class="info-detail">
            <div class="child-nav">
              <li
                :class="['ls-item', { active: idx === personActIdx }]"
                v-for="(ele, idx) in ['详细介绍', '学术成果']"
                :key="idx"
                @click="personActIdx = idx"
              >
                {{ ele }}
              </li>
            </div>
            <div class="detail-content" v-show="personActIdx">
              <article>
                <span>「人物简介」</span>
                <section>
                  包振民，男，汉族，中共党员 [1]
                  ，1964年9月10日生于浙江省杭州市，祖籍浙江省嵊州市谷来镇，
                  阿里巴巴集团主要创始人，现担任日本软银董事、大自然保护协会
                  中国理事会主席兼全球董事会成员、华谊兄弟董事、生命科学突破奖基金会董事、联合国数字合作高级别小组联合主席。
                  [2-3]
                  1988年毕业于杭州师范学院外语系，同年担任杭州电子工业学院英文及国际贸易教师，1995年创办中国第一家互联网商业信息发布网站“中国黄页”，1998年出任中国
                  国际电子商务中心国富通信息技术发展有限公司总经理，1999年创办阿里巴巴，并担任阿里集团CEO、董事局主席。
                  2013年5月，辞任阿里巴巴集团CEO，继续担任阿里集团董事局主席。6月30日，马云当选全球互联网治理联盟理事会联合主席。
                  [4] 2019年9月，马云卸任阿里巴巴
                  董事局主席，继续担任阿里巴巴集团董事会成员。 [5-6]
                  2017年12月，荣获“影响中国”2017年度教育人物 [7]
                  。2018年9月，马云发出公开信宣布将于2019年9月10日卸任集团董事局主席，由CEO张勇接任；12月，党中央、
                  国务院授予马云同志改革先锋称号，颁授改革先锋奖章。 [8]
                  2019年3月，马云以373亿美元财富排名2019年福布斯全球亿万富豪榜第21位；
                  [9] 5月，马云等人被联
                  合国秘书长古特雷斯任命为新一届可持续发展目标倡导者； [10]
                  10月，获得福布斯终身成就奖 [11]
                  ；同年，入选2019福布斯年度商业人物之跨国经营商业领袖名单 [12]
                  。2020年9月30日，马云不再担任阿里巴巴集团董事。 [13]
                  2021年4月，马云以484亿美元财富位列《2021福布斯全球富豪榜》第26名。
                  [166]
                </section>
              </article>
              <article>
                <span>「人物简介」</span>
                <section>包振民，男，汉族，中共党员 [1]</section>
              </article>
              <article>
                <span>「人物简介」</span>
                <section>包振民，男，汉族，中共党员 [1]</section>
              </article>
              <article>
                <span>「研究领域」</span>
                <section>
                  重点开展扇贝的遗传学现象和机理、性状的分子标记和遗传调控网络解析等研究
                </section>
              </article>
            </div>
            <div class="academic-achievements" v-show="!personActIdx">
              <article>
                <span>[期刊] 海藻复合膳食纤维改善小鼠胃肠道功能的实验研究</span>
                <section>
                  作者:吕钟钟,张文竹,李海花,刘红兵,柳明,管华诗
                  来源:中国海洋药物.2009,28,(06),31-35. 摘要:目的根据
                </section>
              </article>
              <article>
                <span>[期刊] 海藻复合膳食纤维改善小鼠胃肠道功能的实验研究</span>
                <section>
                  作者:吕钟钟,张文竹,李海花,刘红兵,柳明,管华诗
                  来源:中国海洋药物.2009,28,(06),31-35. 摘要:目的根据
                </section>
              </article>
              <article>
                <span>[期刊] 海藻复合膳食纤维改善小鼠胃肠道功能的实验研究</span>
                <section>
                  作者:吕钟钟,张文竹,李海花,刘红兵,柳明,管华诗
                  来源:中国海洋药物.2009,28,(06),31-35. 摘要:目的根据
                </section>
              </article>
            </div>
          </div>
        </div>

        <!-- 详情 -->
        <div class="item-detail" v-if="['4', '5'].includes($route.query.type)">
          富文本
        </div>
      </div>
    </div>
    <ToTopperComponent />
    <gateway-footer />
  </div>
</template>
<script>
import GatewayHeader from '../../components/gateway-header.vue'
import GatewayFooter from '../../components/gateway-footer.vue'
import ImgDetailLayout from '../../components/img-detail-layout.vue'
import ToTopperComponent from '../../components/to-topper-component.vue'
import ColumnNewLayout from '../../components/column-new-layout.vue'

import { methods } from '../zhanyu/modules/methods'
import { loadBMap } from '../../utils/baidu-map/loadResources'
import * as _static from './modules/static'
import { utils as _utils } from './modules/public-utils'

import Vue from 'vue'
import {
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Table,
  TableColumn,
  Pagination,
} from 'element-ui'
Vue.use(Form)
  .use(FormItem)
  .use(Input)
  .use(Select)
  .use(Option)
  .use(Table)
  .use(TableColumn)
  .use(Pagination)

export default {
  name: 'sub-pages',
  data() {
    return {
      curData: {}, // 当前页面内的数据
      personActIdx: 0, // 人物介绍内nav
      isSecondLevel: false,
      // listData: [1, 2, 3, 1, 2], // 视频列表
      authString: '', // 显示模块
      pageTitle: '', // 中间大标题
      firstNavName: '', // 导航栏第一个 自负床
      lastNavName: '', // 最后一级的 位置自负床
      // listData: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],

      // 分页数据
      params: {
        searchWord: '',
        pageSize: 10,
        pageNum: 1,
        total: 1000,
      },
      listData: [], // 当前页面内的 列表数据
      // 表格数据
      tablels: [
        {
          name: 78,
          mobile:
            '广东省湛江市麻章区经济开发区金康中路广东省湛江市麻章区经济发区金康中路区金康中路',
        },
        {
          name: 78,
        },
        {
          name: 78,
        },
        {
          name: 78,
        },
      ],
      formInline: {
        user: '',
        region: '',
      },
    }
  },
  methods: {
    ...methods,
    ..._utils,
    toDetail(data) {
      this.isSecondLevel = !this.isSecondLevel
      this.lastNavName += '>会议详情'
    },
    gobackpage() {
      if (this.isSecondLevel) {
        this.isSecondLevel = !this.isSecondLevel
        this.lastNavName = this.lastNavName.split('>')[0]

        return false
      }
      this.$router.push(`/parkService?type=${this.$route.query.type}`)
    },
    // 强自定义地图弹窗
    addInfoWindow(marker, id) {
      let map = this.map
      let that = this
      var infoWindow = new BMapGL.InfoWindow(
        document.querySelector('.window-wrap'),
        {
          width: 344,
          height: 158,
        }
      )
      marker.addEventListener('click', function () {
        that.chartsData = id * id
        document.querySelector('.window-wrap').style.opacity = '1'
        this.openInfoWindow(infoWindow)
        // 图片加载完毕重绘infoWindow
        // document.getElementById('imgDemo').onload = function () {
        //   // map.reset()
        //   infoWindow.redraw() // 防止在网速较慢时生成的信息框高度比图片总高度小，导致图片部分被隐藏
        // }
      })
    },
    // 地图初始化后的主方法 **
    mapHandle() {
      var map = new BMapGL.Map('map-canvas')
      this.map = map
      map.setMapStyleV2({
        styleId: '0b09a3c6ed2d04d268eee6cb8f58ada7',
      })
      map.enableScrollWheelZoom(true) //开启鼠标滚轮缩放

      this.seaArea('subpages')

      this.customeControlClass()

      this.haveHeightPoint()
    },

    loadMapFunc() {
      window.initBaiduMapScript = () => {
        this.mapHandle()
      }
      loadBMap('initBaiduMapScript')
    },
    getListData(isInit) {
      // console.log('getListData')
      if (isInit) this.params.pageNum = 1
      this.listData = []
    },
  },
  components: {
    GatewayHeader,
    GatewayFooter,
    ImgDetailLayout,
    ColumnNewLayout,
    ToTopperComponent,
  },
  computed: {
    // 'search,table,pagination,rowsImg,map'
  },
  beforeMount() {
    // 模板编译/挂载之前
    this.firstNavName = _static.PublishNav[this.$route.query.type - 1].name

    let data = []
    switch (+this.$route.query.type) {
      case 1:
        data = _static.SpecialResource[this.$route.query.id - 1]
        this.pageTitle = data.title
        this.authString = data.auth
        this.lastNavName = this.pageTitle
        break

      case 2:
        data = _static.SpecialService[this.$route.query.id - 1]
        this.pageTitle = data.title
        this.authString = data.auth
        this.lastNavName = this.pageTitle
        break

      case 3:
        data = _static.SpecialResource[this.$route.query.id - 1]
        this.lastNavName = '详情'
        break

      case 4:
        data = _static.SpecialService[this.$route.query.id - 1]
        this.lastNavName = '详情'
        break

      case 5:
        data = _static.SpecialService[this.$route.query.id - 1]
        this.lastNavName = '详情'
        break
    }
  },
  mounted() {
    // 模板编译/挂载之后
    this.authString.includes('map') && this.loadMapFunc()
  },
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  created() {},
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>
<style lang="less" scoped>
@import './sub-pages.less';

</style>
