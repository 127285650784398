<template>
  <div class="gateway park-service">
    <!-- header 头部 -->
    <gateway-header :bgAttr="'rgba(54, 89, 241, 1)'" />

    <div class="park-service-banner">
      <img src="../../assets/img/gateway/bg-banner-home.png" alt="" />
    </div>

    <div class="main-content gateway-module">
      <div class="module-content">
        <div class="sub-nav-box">
          <li
            :class="['ls-item', { active: idx === lsHoverIdx - 1 }]"
            v-for="(ele, idx) in navLs"
            :key="idx"
            @click="clickItem(idx)"
          >
            {{ ele.name }}
          </li>
        </div>

        <!-- 行业政策 -->
        <div class="news-box" v-show="lsHoverIdx === 5">
          <div class="search-box">
            <el-input
              placeholder="请输入标题"
              v-model="params.searchWord"
              @keyup.enter.native="getListData"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <div class="news-ls">
            <li
              :class="['ls-item', { active: ele === 1 }]"
              v-for="ele in listData"
              :key="ele"
              @click="clickSubItem(ele)"
            >
              <p>湛江海洋生态保护不停歇</p>
              <i>2021-09-18</i>
            </li>
          </div>
          <div
            class="more-txt"
            @click="loadMore"
            v-if="listData.length > params.pageSize * params.pageNum"
          >
            查看更多<i></i>
          </div>
        </div>

        <div class="fisheries-wiki" v-show="lsHoverIdx === 4">
          <div class="search-box">
            <el-input
              placeholder="请输入标题"
              v-model="params.searchWord"
              @keyup.enter.native="getListData"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <div class="wiki-ls">
            <li
              :class="['ls-item', { active: ele === 1 }]"
              v-for="ele in listData"
              :key="ele"
              @click="clickSubItem(ele)"
            >
              <th>《家鱼的生物学和人工系列》</th>
              <span>「作者」:施鼎钧</span>
              <p>
                「摘要」:中国现代水产养殖著作。钟麟、李有广、张松涛、刘家照、陈奋昌著。科学出版社1965年出版。该书在家鱼生物学部分，叙述了鲢、鳙和草鱼的食性、生长、性腺发育、生殖以及胚胎发育;在人工繁殖技术部分,详细论述了亲鱼培育、催产、受精、孵化和鱼苗培育5个主要环节。其中关于鲢、鳙的人工繁殖技术，是作者1958年运..
              </p>
              <i>查看详情</i>
            </li>
          </div>
          <div
            class="more-txt"
            @click="loadMore"
            v-if="listData.length > params.pageSize * params.pageNum"
          >
            查看更多<i></i>
          </div>
        </div>

        <div class="academic-circles" v-show="lsHoverIdx === 3">
          <div class="search-box">
            <el-input
              placeholder="请输入学者姓名"
              v-model="params.searchWord"
              @keyup.enter.native="getListData"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <div class="academic-ls">
            <ImgDetailLayout
              :dataList="listData"
              @clickChildItem="clickSubItem"
            />
          </div>
        </div>

        <div class="special-services" v-if="lsHoverIdx === 2">
          <div class="services-ls">
            <ParkServiceLs
              :dataList="servicesls"
              @clickChildItem="clickSubItem"
            />
          </div>
        </div>

        <div class="special-resources" v-if="lsHoverIdx === 1">
          <div class="resources-ls">
            <ParkServiceLs
              :dataList="resourcesls"
              @clickChildItem="clickSubItem"
            />
          </div>
        </div>
      </div>
    </div>

    <gateway-footer style="background: #031236; color: #fff" />
    <ToTopperComponent />
  </div>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css'

import Vue from 'vue'
import { Input } from 'element-ui'
Vue.use(Input)

import GatewayHeader from '../../components/gateway-header.vue'
import GatewayFooter from '../../components/gateway-footer.vue'
import ParkServiceLs from '../../components/park-service-ls.vue'
import ImgDetailLayout from '../../components/img-detail-layout.vue'
import ToTopperComponent from '../../components/to-topper-component.vue'

import { SpecialResource, SpecialService, PublishNav } from './modules/static'
import { utils as _utils } from './modules/public-utils'

export default {
  name: 'park-service',
  data() {
    return {
      navLs: PublishNav,
      listData: [],
      servicesls: [],
      resourcesls: [],
      lsHoverIdx: 1,
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 2000, //1秒切换一次
        },
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      params: {
        searchWord: '',
        pageSize: 10,
        pageNum: 1,
        total: 10,
      },
    }
  },
  computed: {},
  methods: {
    clickItem(idx) {
      this.lsHoverIdx = idx + 1
      sessionStorage.setItem('firstNav', this.navLs[idx].name)
      this.params.searchWord = ''

      // this.$router.push(`/parkService?type=${this.lsHoverIdx}`)
    },
    clickSubItem(data) {
      this.$router.push(`/subPages?id=${data.id || 0}&type=${this.lsHoverIdx}`)
    },
    ..._utils,
    getListData() {
      console.log('getListData')
      this.listData = []
    },
  },
  components: {
    GatewayHeader,
    GatewayFooter,
    ToTopperComponent,
    swiper,
    swiperSlide,
    ParkServiceLs,
    ImgDetailLayout,
  },
  created() {
    this.lsHoverIdx = +this.$route.query.type || 1
    this.resourcesls = []
    let typeName = ''
    SpecialResource.forEach((ele, idx) => {
      if (ele.parName !== typeName) {
        this.resourcesls.push({ title: ele.parName, child: [] })
        typeName = ele.parName
      }

      this.resourcesls[this.resourcesls.length - 1].child.push(ele)
    })

    this.servicesls = []
    SpecialService.forEach((ele, idx) => {
      if (ele.parName !== typeName) {
        this.servicesls.push({ title: ele.parName, child: [] })
        typeName = ele.parName
      }

      this.servicesls[this.servicesls.length - 1].child.push(ele)
    })
  },
  beforeMount() {
    // 模板编译/挂载之前
  },
  mounted() {
    // 模板编译/挂载之后
  },
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>
<style lang="less" scoped>
@import './park-service.less';

</style>
