<template>
  <div class="gateway-header" :style="{ background: bgAttr }">
    <div class="logo">
      <img src="../assets/img/large-screen/76px/100.png" alt="" />
      <h1>湛渔</h1>
    </div>
    <nav class="header-nav">
      <ul>
        <li
          v-for="ele in nav"
          :key="ele.txt"
          @click="!$route.fullPath.includes(ele.link) && $router.push(ele.link)"
        >
          {{ ele.txt }}
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
export default {
  name: 'gateway-header',
  data() {
    return {
      nav: [
        {
          txt: '首页',
          link: '/homepage',
        },
        {
          txt: '湛渔品牌',
          link: '/zyBrand',
        },
        {
          txt: '漫游产业园',
          link: '/industrialPark',
        },
        {
          txt: '产业园服务',
          link: '/parkService',
        },
        {
          txt: '新闻中心',
          link: '/newsCenter',
        },
      ],
    }
  },
  props: {
    bgAttr: String,
  },
  computed: {},
  methods: {},
  components: {},
  beforeMount() {
    // 模板编译/挂载之前
  },
  mounted() {
    // 模板编译/挂载之后
  },
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>
<style lang="less" scoped>
.gateway-header {
  position: absolute;
  z-index: 11;
  top: 0;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  padding: 0 60px;
  background: rgba(54, 89, 241, 0.6);
  .logo {
    display: inline-flex;
    height: 100%;

    align-items: center;
    h1 {
      margin-left: 7px;
      color: #ffffff;
      font-family: 'Microsoft YaHei';
      font-size: 18px;
      font-weight: 400;
    }
    img {
      display: block;
      overflow: hidden;
      width: 27px;
      height: 27px;
      border-radius: 50%;
      background: #ffffff;
    }
  }
  nav {
    float: right;
    height: 100%;
    ul {
      display: flex;
      height: 100%;
      cursor: pointer;

      align-items: center;
      justify-content: center;
    }
    li {
      margin-left: 64px;
      color: #e6e6e6;
      font-family: 'Microsoft YaHei';
      font-size: 14px;
      font-weight: 400;
    }
  }
}

</style>
