<template>
  <footer class="clearfix">
    <div class="footer-content">
      <div class="footer-left">
        <span>全国热线电话</span>
        <span>400-400-4000</span>
        <span>周一到周五9:00-18:00（北京时间）</span>
        <p>2018-广州市湛渔养殖信息科技有限公司 版权所有 粤ICP备17034685号</p>
      </div>
      <div class="footer-right">
        <span>商务合作：business@cl.com</span>
        <span>媒体合作：Media@cl.com</span>
        <span>渠道合作：business_part</span>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'gateway-footer',
  data() {
    return {}
  },
  computed: {},
  methods: {},
  components: {},
  beforeMount() {
    // 模板编译/挂载之前
  },
  mounted() {
    // 模板编译/挂载之后
  },
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>
<style lang="less" scoped>
footer {
  padding: 118px 0;
  background: #031236;
  color: #ffffff;
  .footer-content {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;

    justify-content: space-between;
    .footer-left,
    .footer-right {
      display: flex;
      flex-direction: column;
      height: 152px;
      font-family: 'Microsoft YaHei';
      font-size: 14px;
      font-weight: 400;

      justify-content: space-between;
      p {
        margin-top: 34px;
      }
    }
  }
}

</style>
