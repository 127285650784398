<template>
  <div class="gateway zy-brand">
    <!-- header 头部 -->
    <gateway-header :bgAttr="'rgba(54, 89, 241, 1)'" />

    <div class="zy-brand-banner">
      <img src="../../assets/img/gateway/bg-banner-home.png" alt="" />
    </div>

    <div class="famous-products gateway-module">
      <div class="module-title">湛渔名优产品</div>
      <div class="module-content">
        <div :class="'img-ls-box index_e2 on' + lsHoverIdx">
          <div
            :class="[
              'ls-item index_e3',
              `as${ele}`,
              { on: lsHoverIdx === ele },
            ]"
            v-for="ele in 5"
            :key="ele"
            @mouseover="clickItem(ele)"
          >
            <!-- {{ ele }} -->
            <div class="img">
              <img src="../../assets/img/gateway/bg-banner-home.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="brand-qualification gateway-module">
      <div class="module-title">湛渔品牌资质</div>
      <div class="module-content">
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide v-for="ele in 6" :key="ele">
            <div class="item-wrap">
              <div class="img-box">
                <img src="../../assets/img/large-screen/img-longxia.png" alt />
              </div>
              <span>食品经营许可证</span>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </div>

    <gateway-footer />
    <ToTopperComponent />
  </div>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css'

import GatewayHeader from '../../components/gateway-header.vue'
import GatewayFooter from '../../components/gateway-footer.vue'
import ToTopperComponent from '../../components/to-topper-component.vue'
export default {
  name: 'zy-brand',
  data() {
    return {
      lsHoverIdx: 5,
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 2000, //1秒切换一次
        },
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  computed: {},
  methods: {
    clickItem(idx) {
      console.log(idx)
      this.lsHoverIdx = idx
    },
  },
  components: {
    GatewayHeader,
    GatewayFooter,
    swiper,
    swiperSlide,
    ToTopperComponent,
  },
  beforeMount() {
    // 模板编译/挂载之前
  },
  mounted() {
    // 模板编译/挂载之后
  },
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>
<style lang="less" scoped>
@import './zy-brand.less';

</style>
