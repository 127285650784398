// 特色资源
export const SpecialResource = [
  {
    title: '湛江水产品批发市场价格信息',
    id: 1,
    bannerUrl: '',
    parName: '渔业统计数据',
    iconUrl: '',
    auth: 'search,table,pagination',
    describe:
      '收集湛江市水产批发市场价格数据，包含品种、规格、市场名称，时间长度等',
  },
  {
    title: '湛江渔业统计年鉴',
    id: 2,
    bannerUrl: '',
    parName: '渔业统计数据',
    iconUrl: '',
    auth: 'search,table,pagination',
    describe:
      '收集湛江市渔业生产统计数据，包含养殖、苗种、生产、加工、物流贸易等',
  },
  {
    title: '湛江渔业贸易数据',
    id: 3,
    bannerUrl: '',
    parName: '渔业统计数据',
    iconUrl: '',
    auth: 'search,table,pagination',
    describe: '收集湛江市水产品进出口贸易数据',
  },
  {
    title: '湛江海水苗种资源库',
    id: 4,
    bannerUrl: '',
    parName: '联合服务平台',
    iconUrl: '',
    auth: 'rowsImg,pagination',
    describe: '收集湛江市养殖苗种数据',
  },
  {
    title: '湛江渔业水质监测',
    id: 5,
    bannerUrl: '',
    parName: '渔业资源环境',
    iconUrl: '',
    auth: 'search,table,pagination',
    describe: '收集湛江市十二片区生态养殖水质数据',
  },
  {
    title: '湛江渔业温度监测',
    id: 6,
    bannerUrl: '',
    parName: '渔业资源环境',
    iconUrl: '',
    auth: 'search,table,pagination',
    describe: '收集湛江市十二片区生态养殖水温数据',
  },
  {
    title: '湛江渔业气象监测',
    id: 7,
    bannerUrl: '',
    parName: '渔业资源环境',
    iconUrl: '',
    auth: '',
    linkTo: 'http://data.cma.cn/dataGis/static/gridgis/#/pcindex',
    describe: '收集湛江市十二片区生态养殖气象数据',
  },
  {
    title: '湛江水产品追溯示范企业',
    id: 8,
    bannerUrl: '',
    parName: '渔业质量安全',
    iconUrl: '',
    auth: 'map',
    describe:
      '收集湛江市水产品追溯系统建设示范企业数据，包含企业名称、地址',
  },
  {
    title: '湛江无公害渔业产品',
    id: 9,
    bannerUrl: '',
    parName: '渔业质量安全',
    iconUrl: '',
    auth: 'rowsImg,pagination',
    describe: '收集湛江市三品一标认证产品',
  },
  {
    title: '湛江渔业企业数据',
    id: 10,
    bannerUrl: '',
    parName: '渔业质量安全',
    iconUrl: '',
    auth: 'search,table,pagination',
    describe: '收集湛江市在渔业领域市值、占有率的涉渔企业',
  },
  {
    title: '渔业在线学习',
    id: 11,
    bannerUrl: '',
    parName: '渔业培训服务',
    iconUrl: '',
    auth: 'onlineLearn',
    describe: '收集关于渔业方面知识视频',
  },
]

// 特色服务
export const SpecialService = [
  {
    title: 'FAO统计数据Excel工具',
    id: 1,
    bannerUrl: '',
    parName: '线下工具',
    iconUrl: '',
    auth: '',
    linkTo: 'http://fishery.ckcest.cn/serviceFaodetalis.html',
    describe:
      '该工具是一个由粮农组织开发的用户友好工具，用以促进对水产养殖部门业绩的评估和监测。',
  },
  {
    title: '区域水产养殖适应性评价工具',
    id: 2,
    bannerUrl: '',
    parName: '线下工具',
    iconUrl: '',
    auth: '',
    linkTo: 'http://fishery.ckcest.cn/regionalAquaculture.html',
    describe:
      '借助GIS技术，使用多准则分析方法，实现对影响某区域内水产养殖的各种因素数据处理。',
  },
  {
    title: '学术会议信息服务平台',
    id: 3,
    bannerUrl: '',
    parName: '线上应用',
    iconUrl: '',
    auth: 'none',
    describe:
      '主要发布涉渔行业会议、学术会议、讲座等',
  },
  {
    title: '词表查询',
    id: 4,
    bannerUrl: '',
    parName: '线上应用',
    iconUrl: '',
    auth: '',
    linkTo: 'http://fishery.ckcest.cn/thesaurus/index.html',
    describe:
      '主要是帮助非渔业领域的用户熟悉渔业领域的知识体系，加深渔业领域的用户对渔业领域知识组织的理解',
  },
]

// 公共服务nav
export const PublishNav = [
  { name: '特色资源' },
  { name: '特色服务' },
  { name: '学术圈' },
  { name: '渔业百科' },
  { name: '行业政策' },
]