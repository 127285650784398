<template>
  <div class="ls-body">
    <div
      class="item-wrap"
      v-for="(ele, idx) in dataList"
      :key="idx"
      :style="dataList.length > 3 ? '' : 'margin-bottom: 0px'"
    >
      <li class="ls-item" @click="handleClick(ele)">
        <div class="img">
          <img src="../assets/img/gateway/bg-banner-home.png" alt="" />
        </div>
        <div class="detail">
          <span>包振民</span>
          <p>中国海洋大学院士</p>
          <i>2021-09-18</i>
        </div>
      </li>
    </div>
  </div>
</template>
<script>
export default {
  name: 'img-detail-layout',
  props: {
    dataList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    handleClick(data) {
      this.$emit('clickChildItem', data)
    },
  },
  components: {},
  beforeMount() {
    // 模板编译/挂载之前
  },
  mounted() {
    // 模板编译/挂载之后
  },
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>
<style lang="less" scoped>
.ls-body {
  display: flex;
  width: 100%;

  flex-wrap: wrap;
  .item-wrap {
    width: 33.333%;
    margin-bottom: 84px;
    &:nth-child(3n) .ls-item {
      justify-content: flex-end;
    }
    &:nth-child(3n) + .item-wrap,
    &:first-child {
      .ls-item {
        justify-content: flex-start;
      }
    }
    .ls-item {
      display: flex;
      cursor: pointer;

      justify-content: center;
      &:hover {
        .img img {
          transform: scale(1.1);
        }
      }
      .img {
        display: flex;
        overflow: hidden;
        width: 216px;
        height: 238px;
        border-radius: 6px;

        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          transition: transform 0.3s;
        }
      }
      .detail {
        display: flex;
        flex-direction: column;
        margin-left: 10px;

        align-items: flex-start;
        justify-content: flex-end;
        span {
          margin-bottom: 10px;
          color: #1b1e2a;
          font-family: 'Microsoft YaHei';
          font-size: 24px;
          font-weight: 400;
        }
        p {
          color: #666870;
          font-family: 'Microsoft YaHei';
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
        i {
          color: #666870;
          font-family: 'Microsoft YaHei';
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }
}

</style>
