<template>
  <div class="">
    <li class="ls-item" v-for="(ele, idx) in dataList" :key="idx">
      <div class="h5">「{{ ele.title }}」</div>
      <div class="right-sub-ls">
        <div
          class="sub-ls-item"
          v-for="(item, ip) in ele.child"
          @click="handleClick(item)"
          :key="ip"
        >
          <div class="img">
            <img src="../assets/img/gateway/bg-banner-home.png" alt="" />
          </div>
          <div class="describe">
            <span>{{ item.title }}</span>
            <p>
              {{ item.describe }}
            </p>
          </div>
        </div>
      </div>
    </li>
  </div>
</template>
<script>
export default {
  name: 'park-service-ls',
  props: {
    dataList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    handleClick(item) {
      if (item.linkTo) {
        window.open(item.linkTo)
        return false
      }
      this.$emit('clickChildItem', item)
    },
  },
  components: {},
  beforeMount() {
    // 模板编译/挂载之前
  },
  mounted() {
    // 模板编译/挂载之后
    console.log(this.dataList)
  },
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>
<style lang="less" scoped>
.ls-item {
  overflow: hidden;
  margin-bottom: 36px;
  .h5 {
    float: left;
    margin-top: 15px;
    color: #1b1e2a;
    font-family: 'Microsoft YaHei';
    font-size: 24px;
    font-weight: 400;
  }
  .right-sub-ls {
    display: flex;
    margin-left: 306px;

    flex-wrap: wrap;
    justify-content: space-between;
    .sub-ls-item {
      display: flex;
      width: calc(50% - 52px);
      margin-bottom: 44px;
      cursor: pointer;

      .img {
        overflow: hidden;
        width: 71px;
        height: 71px;
        margin-right: 8px;
        border-radius: 50%;
        background: linear-gradient(
        132deg,
        #fb69f8 0%,
        #089ddd 50%,
        #7afed6 100%
        );

        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .describe {
        display: flex;
        flex-direction: column;
        text-align: left;
        span {
          color: #1b1e2a;
          font-family: 'Microsoft YaHei';
          font-size: 22px;
          font-weight: 400;
        }
        p {
          margin-top: 14px;
          color: #666870;
          font-family: 'Microsoft YaHei';
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }
}

</style>
