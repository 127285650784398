<template>
  <div class="gateway industrial-park">
    <!-- header 头部 -->
    <gateway-header :bgAttr="'rgba(54, 89, 241, 1)'" />

    <div class="industrial-park-banner">
      <img src="../../assets/img/gateway/bg-banner-home.png" alt="" />
    </div>

    <div class="park-layout gateway-module">
      <div class="module-title">产业园空间布局</div>
      <div class="module-content">
        <div class="box-wrap">
          <div class="ls-box">
            <div
              :class="['ls-item', { active: ele === areaHover }]"
              v-for="ele in 5"
              :key="ele"
              @click="areaHover = ele"
            >
              <div class="tag-box">十二</div>
              <div class="text-content">
                <i>「特呈岛海域深海网箱产业核心区」:</i>
                <p>
                  草潭以西海域片区：位于遂溪县草潭以西,与广西海域交界处海域,面积600公顷(9000
                  亩)，规划网箱数790只。
                  乐民港以西海域片区：位于逐溪县乐民港以西，与广西海域交界处海域，面积600公顷(9000
                  亩)，规划网箱数790只。
                  江洪港以西海域片区：位于逐溪县江洪港以西海域，面积600公顷(9000亩)，规划网箱数790只。
                  乌石港海域片区：位于雷州市乌石港南面、东二角与四尾角之间西面海域，面积200公顷(3000亩)，规划网箱数260只。
                  流沙湾海域片区：位于徐闻县流沙湾至北栋湾之间西面海域，近水尾村，面积400公顷(6000
                  亩)，规划网箱数500只。
                  东海岛东南海域片区：位于东海岛南面,近东南码头海域，面积486公顷(7290亩)，规划网箱数640只。
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="map-park-layout map-content">
          <div id="map-canvas"></div>
        </div>
      </div>
    </div>

    <div class="enterprises-settled gateway-module">
      <div class="module-title">产业园入驻企业</div>
      <div class="module-content">
        <div class="sub-nav-box">
          <li
            :class="['ls-item', { active: subNavAct === idx }]"
            v-for="(ele, idx) in subNavls"
            :key="idx"
            @mouseenter="subNavAct = idx"
          >
            {{ ele.txt }}
          </li>
        </div>
        <div class="main-content">
          <div class="left-nav">
            <div class="nav-box">
              <li
                v-for="(ele, idx) in companyls"
                :class="['ls-item', { active: idx === companyAct }]"
                :key="idx"
                @mouseenter="companyAct = idx"
              >
                广东恒兴集团有限公司
              </li>
            </div>
          </div>
          <div class="detail-content">
            <div class="text-detail">
              广东恒兴饲料实业股份有限公司创立于1995年，是一家集饲专利及实用新型专利共73项，与中山大学共同选育第一个国家认定的凡纳滨对虾（南美白对虾）抗病新品种“中兴1号”。
            </div>
            <div class="honors">
              <div class="title">企业荣誉</div>
              <div class="imgs">
                <img src="../../assets/img/gateway/bg-banner-home.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="vr-layout gateway-module">
      <div class="module-title">产业园实景VR</div>
      <div class="module-content">
        <img src="../../assets/img/gateway/bg-banner-home.png" alt="" />
      </div>
    </div>
    <gateway-footer style="background: #031236; color: #fff" />
    <ToTopperComponent />
  </div>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css'

import GatewayHeader from '../../components/gateway-header.vue'
import GatewayFooter from '../../components/gateway-footer.vue'
import ToTopperComponent from '../../components/to-topper-component.vue'
import { methods } from '../zhanyu/modules/methods'
import { loadBMap } from '../../utils/baidu-map/loadResources'
export default {
  name: 'industrial-park',
  data() {
    return {
      companyAct: 0,
      companyls: [
        { type: 1, txt: '苗种繁育' },
        { type: 1, txt: '饲料产销' },
        { type: 1, txt: '深海养殖' },
        { type: 1, txt: '加工仓储' },
        { type: 1, txt: '冷链物流' },
        { type: 1, txt: '苗种繁育' },
        { type: 1, txt: '饲料产销' },
        { type: 1, txt: '深海养殖' },
        { type: 1, txt: '加工仓储' },
        { type: 1, txt: '冷链物流' },
      ],
      subNavAct: 0,
      subNavls: [
        { type: 1, txt: '苗种繁育' },
        { type: 1, txt: '饲料产销' },
        { type: 1, txt: '深海养殖' },
        { type: 1, txt: '加工仓储' },
        { type: 1, txt: '冷链物流' },
      ],
      areaHover: 1,
      lsHoverIdx: 5,
    }
  },
  computed: {},
  methods: {
    clickItem(idx) {
      console.log(idx)
      this.lsHoverIdx = idx
    },
    ...methods,
    // 地图初始化后的主方法 **
    mapHandle() {
      // console.log('完成地图插件加载')
      var map = new BMapGL.Map('map-canvas')
      this.map = map
      map.setMapStyleV2({
        styleId: '0b09a3c6ed2d04d268eee6cb8f58ada7',
      })
      map.enableScrollWheelZoom(true) //开启鼠标滚轮缩放

      this.seaArea('subpages')

      this.customeControlClass()
    },
  },
  components: {
    GatewayHeader,
    GatewayFooter,
    swiper,
    swiperSlide,
    ToTopperComponent,
  },
  beforeMount() {
    // 模板编译/挂载之前
  },
  mounted() {
    // 模板编译/挂载之后
  },
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  created() {},
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>
<style lang="less" scoped>
@import './industrial-park.less';

</style>
